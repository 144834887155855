import React from 'react';
import "../App.css";

function Footer () {
    return (
      <div className="footer">
        <div className="container">
          <div className="row">
            
              <a className='btn btn-warning col-3 ml-auto mr-auto p-2 mb-3 shadow-sm' target="_blank" href="https://www.amazon.com/SOulfully-You-Chanel-Ray/dp/B0B6LNSN35/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1658790121&sr=8-1"><b>Available on Amazon</b></a>
              {/* <a className='btn col-2 ml-auto mr-auto p-2 mb-3' target="_blank" href="https://www.amazon.com/SOulfully-You-Chanel-Ray/dp/B0B6LNSN35/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1658790121&sr=8-1"><b>@</b></a> */}
            
          </div>
         <span className="text-muted">
            <b>Copyright © {new Date().getFullYear()} | SOulfully You</b>
        </span>
        </div>
      </div>
    )
  }

export default Footer;
