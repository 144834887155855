import React, { Component } from 'react';
import '../App.css';

// import aboutpic from "../../src/img/coding.jpg";
import chanelsig from "../../src/img/pics/sig.png";
// import divider from "../../src/img/dividervert.png";
import gitbrad from "../../src/img/pics/author.jpg";
class About extends Component {


  render() {

    return (

      <div className="mt-5  container appcontent">
        <div id="textkey" className="row justify-content-center">

          <div className="col-sm-12 text-center ztop text-dark">
            <h2>About the Author</h2>

          </div>


        </div>
            <div className="col">
             <div className="row">
              

              
              </div>

              <div className='row mt-5 pt-5'>
                <div className="col-md">
                  <img className='avatar' title="Brad Johnston" alt="Brad Johnston" src={gitbrad}></img>
                  <p className="pt-5 text-secondary">Chanel Ray is an up-and-coming creative writer and portrait photographer whose passion for storytelling came at a young age. As a biracial child raised in an area with little diversity, she found herself struggling with both her racial identity and sexual orientation- searching for avenues to express her thoughts and experiences, while also seeking places she could see herself properly represented. This, amongst many of her life’s journeys, inspired “SOulfully You”- a book turned proactive set of exercises that go beyond the words on the page- creating a safe space through simplistic, yet imaginative meditative practices for both children and adults alike. She is now an aunt to a beautiful niece and nephew and lives in Los Angeles with her partner, professional photographer Stepha Dansky.

</p>
                <p className='text-info'>Author photograph by Stepha Dansky Photography</p>
                
                <img className="ml-auto mr-auto sig center" src={chanelsig} alt="SOulfully You" />
                </div>
               
              </div>
              
                
                

        </div>

      </div>



    );
  }

}



export default About;

