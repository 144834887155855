import React from 'react';
// import "../styles/Header.css";
import Nav from './Navbar.js';

function Header () {
    return (
      <div>
        <Nav />
       </div>
    )
  }

export default Header;