import React, { Component } from 'react';
import '../App.css';

// import logo from "../../src/img/pics/title.png";
import cover1pic from "../../src/img/pics/cover1.png";
import cover2pic from "../../src/img/pics/cover2.png";
import reactpic from "../../src/img/pics/cover3.png";
import cover4pic from "../../src/img/pics/cover4.png";
import cover5pic from "../../src/img/pics/cover5.png";


class Home extends Component {

  render() {

    return (
      
        

        
        <div className="container">
        
          <div className="row">
            
            <div className="col-sm-6 logobox mt-5 mb-5">
              
              
            </div>
            <h5 className='text-secondary p-4 ml-auto mr-auto'>“SOulfully You” goes beyond the words on the page- creating a safe space through simplistic, yet imaginative meditatative practices for both children and adults alike. Grounding, re-centering, and exploring your “inner child” with your own… <br />free yourself to be “SOulfully You.”</h5>
          
          </div>


          <div className="row pt-5">
            <div className='col-8 ml-auto mr-auto center coverspace'>
                <div id="cover1key" className="cover1card">
                  <img id="cover1pickey" className="cover1pic" src={cover1pic} alt="cover1.js"></img>
                </div>
            
                <div id="cover2key" className="cover2card shadow-sm">
                  <img id="cover2pickey" className="cover2pic" src={cover2pic} alt="cover2.js"></img>
                </div>
          
                <div id="cover3key" className="cover3card">
                  <img id="cover3pickey" className="cover3pic" src={reactpic} alt="cover3.js"></img>
                </div>
              
                <div id="cover4key" className="cover4card">
                  <img id="cover4pickey" className="cover4pic" src={cover4pic} alt="cover4"></img>
                </div>
            
                <div id="cover5key" className="cover5card">
                  <img id="cover5pickey" className="cover5pic" src={cover5pic} alt="cover5DB"></img>
                </div>
            </div>
            
          </div>
         
        </div>
      
    );
  }

}



export default Home;

