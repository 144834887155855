import React from "react";
import "../App.css";
import Home from '../pages/home';
import About from '../pages/about';
import Portfolio from '../pages/portfolio';


import {
  Switch,
  Route
} from "react-router-dom";


function Core () {
    return (
    
      
      <Switch>
        <Route path="/" exact component={Home} />

        <Route path="/about" exact component={About} />
        
        <Route path="/portfolio" exact component={Portfolio} />
      
      </Switch>
      
    );
  };

export default Core;